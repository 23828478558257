
window.axios = require('axios');

let token = document.head.querySelector('meta[name="csrf-token"]');
let auth_token = document.head.querySelector('meta[name="auth-token"]');

if (auth_token) {
    window.axios.defaults.headers.common['Authorization'] = 'Bearer '+auth_token.content;
}
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}
console.log('Made by:\n' +
    '    ___                             ______                __  _               ____                 __                                 __ \n' +
    '   /   |  ____  ___  _________     / ____/_______  ____ _/ /_(_)   _____     / __ \\___ _   _____  / /___  ____  ____ ___  ___  ____  / /_\n' +
    '  / /| | / __ \\/ _ \\/ ___/ __ \\   / /   / ___/ _ \\/ __ `/ __/ / | / / _ \\   / / / / _ \\ | / / _ \\/ / __ \\/ __ \\/ __ `__ \\/ _ \\/ __ \\/ __/\n' +
    ' / ___ |/ /_/ /  __/ /  / /_/ /  / /___/ /  /  __/ /_/ / /_/ /| |/ /  __/  / /_/ /  __/ |/ /  __/ / /_/ / /_/ / / / / / /  __/ / / / /_  \n' +
    '/_/  |_/ .___/\\___/_/   \\____/   \\____/_/   \\___/\\__,_/\\__/_/ |___/\\___/  /_____/\\___/|___/\\___/_/\\____/ .___/_/ /_/ /_/\\___/_/ /_/\\__/  \n' +
    '      /_/                                                                                             /_/                                \n');
console.log('https://www.aperobuilt.it');

/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */
window.VueResources = require('vue-resource');
window.Vue = require('vue');
//window.Dropzone = require('vue2-dropzone');
Vue.use(VueResources);


// Vue.http.headers.common['X-CSRF-TOKEN'] = document.querySelector('input[name=_token]').getAttribute('value');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the body of the page. From here, you may begin adding components to
 * the application, or feel free to tweak this setup for your needs.
 */
if (document.getElementById('entryId')) {
    var entryId = document.getElementById('entryId').innerText;
}
if(localStorage.getItem('hide-instructions-' + entryId) == 1) {
    var activeVal = false;
} else {
    var activeVal = true;
}

window.$ = window.jQuery = require('jquery');
import Sortable from 'sortablejs';
window.Sortable = Sortable;
import SignaturePad from "signature_pad";
window.SignaturePad = SignaturePad;
import autosize from "autosize/dist/autosize";
window.autosize = autosize;

import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)

Vue.config.devtools = process.env.NODE_ENV === 'development';

import JudgingPanels from "./vue-components/JudgingPanels";
import JudgingInstructions from "./vue-components/JudgingInstructions";
import JudgingFormQuestions from "./vue-components/JudgingFormQuestions.vue";

var app = new Vue({
    el: '#home',
    components: {
        JudgingPanels,
        JudgingInstructions,
        JudgingFormQuestions,
    },
    data: {
        active: activeVal,
        activeEmail: false,
        activeCC: false,
        greyCC: true,
        greyEmail: true,
        selectItems: true,
        totalDue: 0,
        boxesChecked: true,
    },
    created: function() {

    },
    methods: {
        liked: function() {
            if (this.active) {
                localStorage.setItem('hide-instructions-' + entryId, '1')
            } else {
                localStorage.removeItem('hide-instructions-' + entryId)
            }
            this.active = !this.active;
        },
        showEmail: function() {
            if (this.activeEmail) {
                this.greyCC = '';
            } else {
                this.greyCC = 'disable';
                this.greyEmail = '';
            }
            this.activeCC = false;
            this.activeEmail = !this.activeEmail;
        },
        showCC: function() {
            if (this.activeCC) {
                this.greyEmail = '';
            } else {
                this.greyCC = '';
                this.greyEmail = 'disable';
            }
            this.activeEmail = false;
            this.activeCC = !this.activeCC;
        },
    },
    computed: {
        iconClass: function() {
            return this.active ? 'fa fa-caret-up' : 'fa fa-caret-down'
        },
        buttonText: function () {
            return this.active ? 'Hide Instructions' : 'Show Instructions'
        },
    }
});
