<template>
  <div>
    <div class="accordion-item accordion-contact-details-wrapper mb-5" id="order-step-0">
      <div class="accordion-header position-relative cursor-pointer d-flex justify-content-between" id="order-step--1">
        <a @click.prevent="applicationStepSelector" class="w-100 header-button position-relative d-flex justify-content-between accordion-button px-2 py-3">
          <div class="d-flex align-items-center justify-content-between w-100 app-step-h3-header">
            <h3 class="mb-0">{{ panel_name }}</h3>
            <div class="d-flex align-items-center">
              <template v-if="accordionOpen">
                <span class="label-style">Close</span>
                <close-icon class="ml-2"></close-icon>
              </template>
              <template v-else>
                <span class="label-style">Expand</span>
                <up-chevron-icon class="svg-accent rotate-180 ml-2"></up-chevron-icon>
              </template>
            </div>
          </div>
        </a>
      </div>
      <div id="accordion-order-details" class="border-radius-0 card-bg-white text-bg-light accordion-collapse collapse" :class="(accordionOpen) ? 'show' : ''">
        <div class="fields-wrapper-row pl-30">
          <a v-for="entry in entries" :href="'/entries/'+ entry.application_id + '/judging'" class="py-2 px-2 d-flex justify-content-between align-items-center judging-app-details-wrapper" :class="entry.judging_status">
            <div class="judging-app-details">
              {{ entry.application_id }} - <span class="entries-entrant-name">{{ entry.submitterName }}</span>
            </div>
            <div class="status">
              <div class="status__submission" :class="entry.judging_status">
                <checkmark v-if="entry.judging_status == 'Complete'" class="svg-green"></checkmark>
                <up-chevron-icon v-else class="svg-black rotate-90"></up-chevron-icon>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UpChevronIcon from "../Icons/up-chevron.vue";
import Checkmark from "../Icons/checkmark.vue";
import RightSmall from "../Icons/right-small.vue";
import CloseIcon from "../Icons/close.vue";

export default {
  components: {CloseIcon, RightSmall, Checkmark, UpChevronIcon},
  props: {
    entries: {
      type: Array,
      required: true
    },
    panel_name: {
      type: String,
      required: true
    },
    panel_open: {
      type: Boolean,
    }
  },
  data() {
    return {
      accordionOpen: this.panel_open,
    }
  },
  methods: {
    applicationStepSelector: function() {
      this.accordionOpen = !this.accordionOpen
    }
  }
}
</script>