<template>
  <div>

    <div class="question-group">
      <p class="question"><strong>Clear Interest/Career Goals:</strong> Has the applicant fully conveyed their passion and commitment to their television career?</p>
      <div class="form-group">
        <select class="form-control" name="q_1" v-model="judgementValues.q_1">
          <option value="">Select a value</option>
          <option v-for="index in 5">{{ index }}</option>
        </select>
      </div>
    </div>

    <div class="question-group">
      <p class="question"><strong>Maturity/Sincerity:</strong> These are important qualities for an intern to be successful. Rank your impression of how they’ve expressed this.</p>
      <div class="form-group">
        <select class="form-control" name="q_2" v-model="judgementValues.q_2">
          <option value="">Select a value</option>
          <option v-for="index in 5">{{ index }}</option>
        </select>
      </div>
    </div>

    <div class="question-group">
      <p class="question"><strong>Perceived Talent/Abilities:</strong> Do you think this applicant has what it takes to maximize this internship opportunity?</p>
      <div class="form-group">
        <select class="form-control" name="q_3" v-model="judgementValues.q_3">
          <option value="">Select a value</option>
          <option v-for="index in 5">{{ index }}</option>
        </select>
      </div>
    </div>

    <div class="question-group">
      <p class="question">The Foundation has a clear commitment to opening doors to the television industry. Based on your review of the material (background, no evident business connections, etc.), does this applicant merit additional consideration because he/she may not otherwise have access to the industry without this internship opportunity?</p>
      <div class="form-group">
        <select class="form-control" name="q_4" v-model="judgementValues.q_4">
          <option value="">Select a value</option>
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
      </div>
    </div>

    <div class="text-right">
      <button :disabled="disabledInput ? 'disabled' : ''" @click.prevent="submitOrderModal = true" class="submit-button btn btn-primary btn-bigger-font"><i class="fa fa-check-circle-o mr-2"></i>Submit</button>
    </div>

    <modal v-if="submitOrderModal" @close="submitOrderModal = false">
      <h3 slot="header">Submit Scores</h3>
      <div class="content" slot="body">
        <p>You are submitting your score. You will be redirected to the next application or back to the application list if you are complete.</p>
      </div>
      <div class="form-group-submit" slot="footer">
        <button class="pull-left btn-black" @click.prevent="submitOrderModal = false">Close</button>
        <a :href="return_link" class="btn btn-primary pull-right">Submit</a>
      </div>
    </modal>

  </div>

</template>

<script>
import Modal from "./Modal.vue";
export default {
  components: {
    Modal
  },
  props: {
    return_link: {
      type: String,
      required: true
    },
    judgement: {
      type: Object,
    }
  },
  data() {
    return {
      disabledInput: false,
      submitOrderModal: false,
      judgementValues: this.judgement
    }
  },
  mounted() {
    if(this.judgement.q_1 != null && this.judgement.q_2 != null && this.judgement.q_3 != null && (this.judgement.q_4 != null)) {
      $('#entry-judgement-form .submit-button').removeAttr('disabled');
    }
  }
}

</script>