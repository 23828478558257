<template>
  <div>
    <div class="d-flex border-bottom justify-content-between align-items-center wysiwyg-content judging-full-instructions-wrapper" data-instructions="judging-instructions">
      <h2 class="mb-2">Judging Instructions</h2>
      <div>
        <a class="btn btn-secondary mb-2" @click.prevent="showOrHideInstructions()"><template v-if="showInstructions">Hide</template><template v-else>Show</template> Instructions</a>
      </div>
    </div>
    <div class="judging-instructions-wrapper section-indent-left" v-if="showInstructions">
      <p v-html="judging_instructions"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    judging_instructions: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showInstructions: localStorage.getItem('showInstructions') ? false : true,
    }
  },
  methods: {
    showOrHideInstructions: function() {
      this.showInstructions = !this.showInstructions
      if (this.showInstructions) {
        localStorage.removeItem('showInstructions')
      } else {
        localStorage.setItem('showInstructions', 'false')
      }
    }
  }
}
</script>